// create a functional component that returns a div with a className of "dao"
//  this page will be the dashboard page for the dao

const DAO_ADDR = ""

export const Proposals = () =>{
    return(
        <div className="">

        </div>
    );
}


const Dao = () => {
    return (
        <div className="dao h-screen">
            <p className="text-4xl shadow-bl ">Seam Dao</p>
            {/* <DAOOverview /> */}
            {/* <Proposals /> */}
        </div>
    )
}

const DAOOverview = () => {



    return(
        <div>

        </div>
    );
}

export default Dao