export const dapps = [
  // {
  //   "name": "Tsunami Finance",
  //   "tags": ["DeFi"],
  //   "description": "",
  //   "url": "https://tsunami.finance/",
  //   "discord": "",
  //   "Published": "Jul 25, 2022 10:00 AM",
  //   "twitter": "https://twitter.com/Tsunamifinance_",
  //   "status": "",
  //   "image": "tsunami.png",
  //   "github": "",
  //   "docs": "https://docs.tsunami.finance/",
  //   "Npm": "",
  //   "k": "DEFI"
  // },
  {
    "name": "Switchboard",
    "tags": ["Oracle"],
    "discord": "",
    "Published": "",
    "twitter": "https://twitter.com/switchboardxyz",
    "status": "TBA",
    "url": "https://switchboard.xyz/",
    "image": "switchboard.png",
    "github": "https://github.com/switchboard-xyz/sbv2-aptos",
    "address": "0x34e2eead0aefbc3d0af13c0522be94b002658f4bef8e0740a21086d22236ad77",
    "docs": "https://docs.switchboard.xyz/introduction",
    "npm": "",
  },
  {
    "name": "KanaLabs",
    "tags": ["DEX", "Staking"],
    "discord": "https://discord.com/invite/CrqCaQ45kZ",
    "Published": "",
    "twitter": "https://app.kanalabs.io/",
    "status": "TBA",
    "url": "https://app.kanalabs.io/",
    "image": "kanaLabs.jpg",
    "docs": "https://docs.switchboard.xyz/introduction",
    "address":"0x62fdfe47c9c37227be1f885e79be827be292fe1833ac63a2fe2c2c16c55ecb12",
    "medium": "https://kanalabs.medium.com/",
    "npm": "",
    
  },
  {
    "name": "Aptin",
    "tags": ["lending"],
    "discord": "",
    "twitter": "https://twitter.com/AptinLabs",
    "status": "TBA",
    "url": "https://app.aptin.io/",
    "image": "aptin.jpeg",
    "github": "",
    "address": "0xac39575e72d6717001bda33bb9c355715e3f23d4f7b6952f7cf6f3dbfae60674",
    "docs": "",
    "npm": "",
  },
  
  {
    "name": "Econia",
    "description": "hyper-parallelized on-chain order book for the aptos blockchain",
    "tags": ["DeFi", "Orderbook"],
    "discord": "https://discord.gg/ydAeGtrW",
    "twitter": "https://twitter.com/EconiaLabs",
    "image": "econia.jpg",
    "status": "Testnet",
    "url": 'https://www.econia.dev/',
    "github": "https://github.com/econia-labs",
    "docs": "https://www.econia.dev/",
    "address": "0xc0deb00c9154b6b64db01eeb77d08255300315e1fa35b687d384a703f6034fbd",
  },
  
  {
    "name": "Aptos-Names",
    "tags": ["Name Service", "Tools"],
    "discord": "",
    "published": "Jul 19, 2022 6:28 AM",
    "twitter": "https://twitter.com/aptosnames",
    "status": "Testnet",
    "github": "",
    "url": "https://www.aptosnames.com/",
    "image": "aptosnames.svg",
    "address": "0x867ed1f6bf916171b1de3ee92849b8978b7d1b9e0a8cc982a3d19d535dfd9c0c"
  },
  {
    "name": "BlueMove",
    "tags": ["NFT"],
    "discord": "https://discord.gg/PcRGces7WM",
    "twitter": "https://twitter.com/bluemovenft",
    "status": "devnet",
    "github": "",
    "docs": "https://docs.bluemove.net/",
    "url": "https://bluemove.net/",
    "image": "blueMove.png",
    "address": "0xd1fd99c1944b84d1670a2536417e997864ad12303d19eac725891691b04d614e"
  },
  {
    "name": "Hippo Labs",
    "tags": ["Aggregator", "DeFi", "Tools"],
    "description": "",
    "discord": "",
    "twitter": "https://twitter.com/hippolabs__",
    "status": "",
    "image": "hippoLabs.jpeg",
    "github": "https://github.com/hippospace",
    "url": "https://hippo.space/",
    "docs": "",
    address: "0x89576037b3cc0b89645ea393a47787bb348272c76d6941c574b053672b848039",
    "coin_list": ''
  },
  {
    "name": "Souffl3",
    "tags": ["NFT"],
    "description": "",
    "discord": "https://discord.com/invite/souffl3",
    "twitter": "https://twitter.com/souffl3",
    "status": "",
    "url": "https://souffl3.com/",
    "image": "souffl3.jpg",
    "github": "",
    "docs": "",
    "address": "0xf6994988bd40261af9431cd6dd3fcf765569719e66322c7a05cc78a89cd366d4"
  },
  {
    "name": "Aries",
    "description": "Decentralised Margin Trading",
    "tags": ["DeFi","Lending","Borrowing"],
    "url": "https://app.ariesmarkets.xyz/lending",
    "discord": "https://discord.gg/h6Kx9pkp",
    "twitter": "https://twitter.com/AriesMarkets",
    "address": "0x9770fa9c725cbd97eb50b2be5f7416efdfd1f1554beb0750d4dae4c64e860da3",
    "status": "mainnet",
    "image": "aries.jpg",
    "github": "",
    "docs": "",
  },


  {
    "name": "Umi",
    discord: "https://discord.com/invite/D4kMhm7Ktk",
    image: "umi.png",
    url: "https://umi.ag/swap",
    description: "",
    tags: ["DEX", "DeFi","Router"],
    Published: "Jul 19, 2022 6:53 AM",
    twitter: "https://twitter.com/EnchanterFi",
    address: "0xbeaa9e5ef5bee0781476a4adf293aae7dc3a28e9bd79fda89fca7211fb94c80",
    status: "Mainnet",
    github: "",
    Docs: "",
    Npm: "",
  },


  {
    "name": "Thala Labs",
    "tags": ["DeFi"],
    "discord": "",
    "Published": "Jul 21, 2022 7:50 PM",
    "twitter": "https://twitter.com/ThalaLabs",
    "image": "thala.png",
    "url": "https://www.thalalabs.xyz/",
    "status": "TBA",
    "logo": "",
    "Github": "",
    "Docs": "",
  },
  {
    "name": "Able",
    "tags": ["Lending"],
    "discord": "",
    "Published": "Jul 21, 2022 7:50 PM",
    "twitter": "https://twitter.com/ThalaLabs",
    "image": "abel.png",
    "url": "https://www.thalalabs.xyz/",
    "status": "TBA",
    "logo": "",
    "Github": "",
    "address": "0xc0188ad3f42e66b5bd3596e642b8f72749b67d84e6349ce325b27117a9406bdf",
    "Docs": "https://aptos-lend.gitbook.io/abel-finance/",
  },
  {
    "name": "1kx",
    "tags": ["DeFi", "Exchange"],
    "discord": "",
    "twitter": "https://twitter.com/1kxprotocol",
    "status": "Testnet",
    "image": "1kx.jpg",
    "github": "",
    "docs": "",
    "npm": "",
  },
  {
    "name": "ultima",
    "tags": ["DEX", "Exchange"],
    "discord": "",
    "twitter": "https://twitter.com/ultimaprotocol",
    "status": "",
    "image": "ultima.jpg",
    "github": "",
    "docs": "",
    "Npm": "",
  },
  {
    "name": "AptosLend",
    "tags": ["Lending"],
    "discord": "",
    "twitter": "https://twitter.com/aptoslend",
    "status": "TBA",
    "image": "aptosLend.png",
    "github": "",
    "Docs": "",
    "Npm": "",
  },

  {
    "name": "liquidSwap",
    "description": "",
    "tags": ["DEX"],
    "discord": "https://discord.gg/kHWnQpBq",
    "twitter": "https://twitter.com/PontemNetwork",
    "status": "mainnet",
    "logo": "",
    "github": "https://github.com/pontem-network",
    "url": "https://liquidswap.com/#/",
    "image": "pontemNetwork.jpg",
    "address": "0x190d44266241744264b964a37b8f09863167a12d3e70cda39376cfb4e3561e12",
  },

  {
    "name": "Laminar",
    "tags": ["DEX", "Orderbook"],
    "description": "https://laminar.markets/",
    "discord": "https://discord.gg/h6Kx9pkp",
    "Published": "Jul 19, 2022 5:48 AM",
    "twitter": "https://twitter.com/LaminarMarkets",
    "status": "TBA",
    "image": "laminar.svg",
    "url": "https://laminar.markets/",
    "github": "",
    "docs": "https://medium.com/@Laminar",
  },
  {
    "name": "AnimeSwap",
    "tags": ["DEX", "swap"],
    "description": "Dex w/ a kiss of anime ",
    "discord": "",
    "Published": "Jul 19, 2022 5:48 AM",
    "twitter": "https://twitter.com/animeswap_org",
    "status": "mainnet",
    "image": "animeswap.png",
    "address": "0x16fe2df00ea7dde4a63409201f7f4e536bde7bb7335526a35d05111e68aa322c",
    "url": "https://app.animeswap.org/#/swap?chain=aptos_devnet",
    "github": "https://github.com/AnimeSwap",
    "docs": "https://medium.com/@Laminar",
  },
  {
    "name": "Mojito",
    "tags": ["Prediction market"],

    "twitter": "https://twitter.com/MojitoMarkets",
    "status": "TBA",
    "description": "Mojito Markets is building the global decentralised prediction markets on the blockchain",
    "image": "mojito.png",
    "url": "https://app.mojito.markets",
    "github": "",
    "docs": "",
    "address": "0xa61f1c8f0e69bb866f2073f9a1ab0252efdd9f12c092256c68290d8dae35551e",
  },

  {
    "name": "Martian",
    "tags": ["Wallet"],
    "discord": "https://discord.gg/MQDnAjPN",
    "twitter": "https://twitter.com/martian_wallet",
    "url": "https://martianwallet.xyz/",
    "github": "",
    "image": "martian.png",
    "docs": "https://docs.martianwallet.xyz/docs/",
  },
  {
    "name": "Fewcha",
    "tags": ["Wallet", "Tools"],
    "description": "",
    "discord": "https://discord.gg/HRbMWmhm",
    "twitter": "https://twitter.com/FewchaWallet",
    "status": "Testnet",
    "image": "fewcha.svg",
    "url": "https://fewcha.app/",
    "github": "",
    "docs": "",
  },
  {
    "name": "Topaz",
    "tags": ["Marketplace", "NFTs"],
    "discord": "",
    "Published": "Jul 19, 2022 5:45 AM",
    "twitter": "https://twitter.com/TopazMarket",
    "status": "Testnet",
    "image": "topaz.png",
    "address": "0x2c7bccf7b31baf770fdbcc768d9e9cb3d87805e255355df5db32ac9a669010a2",
    "url": 'https://www.topaz.so/',
    "github": "",
    "docs": "",
    "npm": "",
  },
  {
    "name": "PankcakeSwap",
    "tags": ["Bridge", "Dex"],
    "discord": "",
    "Published": "Jul 19, 2022 5:45 AM",
    "twitter": "https://twitter.com/TopazMarket",
    "status": "Testnet",
    "image": "pancakeSwap.png",
    "address": "0xc7efb4076dbe143cbcd98cfaaa929ecfc8f299203dfff63b95ccb6bfe19850fa",
    "url": 'https://aptos.pancakeswap.finance/liquidity',
    "github": "",
    "docs": "",
    "npm": "",
  },
  {
    "name": "Pankcake(masterchef)",
    "tags": ["Bridge", "Dex"],
    "discord": "",
    "Published": "Jul 19, 2022 5:45 AM",
    "twitter": "https://twitter.com/TopazMarket",
    "status": "Testnet",
    "image": "pancakeSwap.png",
    "address": "0x7968a225eba6c99f5f1070aeec1b405757dee939eabcfda43ba91588bf5fccf3",
    "url": 'https://aptos.pancakeswap.finance/liquidity',
    "github": "",
    "docs": "",
    "npm": "",
  },

  {
    "name": "ATODEX",
    "tags": ["DEX", "Yield Aggregator"],
    "discord": "",
    "Published": "Jul 19, 2022 5:45 AM",
    "twitter": "https://twitter.com/TopazMarket",
    "status": "Testnet",
    "image": "atoDEX.jpeg",
    "address": "0xaea7e219aa758cc115c8fba5e7323b6d17ed1a8b5ceb70900bdeb821cb2f75d2",
    "url": 'https://testnet.atodex.io/swap/',
    "github": "",
    "docs": "",
    "npm": "",
  },

  {
    "name": "Tortuga",
    "tags": ["Staking"],
    "discord": "",
    "Published": "Jul 19, 2022 5:45 AM",
    "twitter": "https://twitter.com/TortugaFinance",
    "status": "Mainnet",
    "image": "tortuga.png",
    "address": "0x2f37694be6350f94bbd49e2e87bafd85c79096dcdb155f3071b2fd2a02ac0e2c",
    "url": 'https://tortuga.finance/',
    "github": "",
    "docs": "",
    "npm": "",
  },

  {
    "name": "Leizd",
    "tags": ["defi", "lending"],
    "doc": "https://docs.leizd.com/",
  },
  { 
    "name": "Ferum",
    "tags": ["DEX", "Exchange"],
    "url" : "https://www.ferum.xyz/",
    "docs": "",
    "github": "",
    "image": "Ferum.jpg",
    "address":"0x728891241aa0dc576e23fa368c168f657b1364eb909c8444f28bc7e4a4a2268d",
    "twitter": "https://twitter.com/ferumxyz",
    "discord": "https://t.co/8DHuqgzhWo",
  },
  { 
    "name": "Argo",
    "tags": ["Stablecoin"],
    "url" : "https://argo.fi/",
    "docs": "https://docs.argo.fi/",
    "github": "",
    "address": "0x98298d34bcf896c663e069c464754e0cfd36b50e21eedd8db0e4189168057cb7",
    "image": "argo.jpg",
    "twitter": "https://twitter.com/ArgoUSD",
    "discord": "https://argo.community/",
  },
  { 
    "name": "HoustonSwap",
    "tags": ["DEX","Concentrated Liquidity"],
    "url" : "https://houstonswap.io/#",
    "docs": "https://docs.houstonswap.io/houstonswap/",
    "github": "",
    "address":"0x7ab72b249ec24f76fe66b6de19dcee1e3d3361db5c2cccfaa48ea8659060a1bd",
    "image": "HoustonSwap.svg",
    "twitter": "https://twitter.com/HoustonSwap",
    "discord": "https://discord.com/invite/bvC6uXyMvy",
  },
  { 
    "name": "AptFlip",
    "tags": ["Gaming"],
    "url" : "https://aptflip.com/flip",
    "docs": "https://medium.com/@APTflip",
    "github": "",
    "address":"0x783ea0c95907f6d1269647fa2516bb41966ef24c4446a854722ac2ebd1e39613",
    "image": "aptFlip.png",
    "twitter": "https://twitter.com/Aptosflip",
    "discord": "https://discord.com/invite/bvC6uXyMvy",
  },
  { 
    "name": "SolCasino",
    "tags": ["Gaming"],
    "url" : "https://solcasino.io/",
    "docs": "https://medium.com/@APTflip",
    "github": "",
    "address":"0xfc2b127d26d6477b2114562a060809dc2acfa723fd130a1f5356a18e1c697",
    "image": "solCasino.jpeg",
    "twitter": "https://twitter.com/Solcasinoio",
    "discord": "na"
  },

  { 
    "name": "Cetus",
    "tags": ["DEX", "Exchange"],
    "url" : "https://app.cetus.zone/",
    "docs": "https://t.co/pnxTzMsIqe",
    "github": "",
    "address":"0xa7f01413d33ba919441888637ca1607ca0ddcbfa3c0a9ddea64743aaa560e498",
    "image": "cetus.png",
    "twitter": "https://twitter.com/CetusProtocol",
    "discord": "https://t.co/pnxTzMsIqe",
  },

  
  { 
    "name": "Wormhole",
    "tags": ["Bridge"],
    "url" : "https://linktr.ee/wormholecrypto",
    "github": "",
    "address":"0x5bc11445584a763c1fa7ed39081f1b920954da14e04b32440cba863d03e19625",
    "image": "wormhole.jpg",
    "twitter": "https://twitter.com/wormholecrypto",
    "discord": "https://t.co/pnxTzMsIqe",
  },

  { 
    "name": "LayerZero",
    "tags": ["Bridge"],
    "url" : "https://layerzero.network/",
    "docs": "https://t.co/pnxTzMsIqe",
    "github": "",
    "address":"0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa",
    "image": "LayerZero.jpg",
    "twitter": "https://twitter.com/LayerZero_Labs",
  },

  {
    "name": "Arco",
    "tags": ["Lending", "Borrowing"],
    "discord": "https://discord.com/invite/CrqCaQ45kZ",
    "Published": "",
    "twitter": "https://app.kanalabs.io/",
    "status": "TBA",
    "url": "https://arcoprotocol.tech/lend",
    "address": "0x62fdfe4c9c37227be1f885e79be827be292fe1833ac63a2fe2c2c16c55ecb12",
    "image": "Arco.png",
  },

  {
    "name": "Ditto",
    "tags": ["Staking", "Liquid Staking"],
    "discord": "https://discord.com/invite/CrqCaQ45kZ",
    "Published": "",
    "twitter": "",
    "address": "0xd11107bdf0d6d7040c6c0bfbdecb6545191fdf13e8d8d259952f53e1713f61b5",
    "url": "https://stake.dittofinance.io/",
    "image": "ditto.png",
  },
  {
    "name": "Pyth",
    "tags": ["Oracle"],
    "discord": "https://discord.com/invite/CrqCaQ45kZ",
    "Published": "",
    "twitter": "https://twitter.com/PythNetwork",
    "status": "TBA",
    "docs": "https://docs.pyth.network/",
    "url": "https://pyth.network/",
    "address": "0xb31e712b26fd295357355f6845e77c888298636609e93bc9b05f0f604049f434",
    "image": "pyth.png",
  },

  {
    "name": "Rafflor",
    "tags": ["Raffles", "NFTs"],
    "status": "TBA",
    "url": "https://stake.dittofinance.io/",
    "image": "Rafflor.png",
    "twitter": "https://twitter.com/Rafflor",
    "address": "0x22df18573dbf63cc0c05f695ef91331f6b6480c4445a891799b1b407ea987baf",
  },
  
  {
    "name": "LeverX",
    "tags": ["Dex", "Derivatives"],
    "status": "TBA",
    "twitter":"https://twitter.com/LeveragedX",
    "medium":"https://medium.com/@leverx_17257",
    "url": "https://linktr.ee/lever_x",
    "image": "LeverX.png",
  },

  {
    "name": "AptosDragons",
    "tags": ["Casino", "Gaming"],
    "twitter":"https://twitter.com/aptosdragonsnft",
    "url": "https://aptos-dragons-roulette.vercel.app/",
    "image": "AptosDragons.png",
    "address":"0x4ab7514a82f84f597bf35d7c51e2e4460ddb9a5cf80e2ea23a5c97eea475713b",
  },

  {
    "name": "AUX",
    "tags": ["DEX"],
    "twitter":"https://twitter.com/AuxExchange",
    "url": "https://aux.exchange/",
    "image": "aux.svg",
    "address":"0xbd35135844473187163ca197ca93b2ab014370587bb0ed3befff9e902d6bb541",
  },
  {
    "name":"Spectrum",
    "tags": ["multi-sig"],
    "twitter":"https://twitter.com/SpecProtocol",
    "url": "https://aptos.spec.finance/vaults",
    "image": "spectrum.svg",
    "address":"0x14569c912dbe4b3916c201c454b47a749427eab5d56e0f1eaba971f6ade648a5",
  },

  {
    "name": "MSafe",
    "tags": ["multi-sig"],
    "twitter":"https://mobile.twitter.com/momentumsafe",
    "url": "https://app.m-safe.io/",
    "image": "mSafe.jpg",
    "address":"0xaa90e0d9d16b63ba4a289fb0dc8d1b454058b21c9b5c76864f825d5c1f32582e",
  },
]
