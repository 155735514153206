export const opto_specs = {
    cpu: {
        model: "i7 4770"
    },
    ram: "16gb (2x8gb)",
    storage: "128gb ssd",
    clients: [
        {
            name: "Aptos node",
            url: "",
        },
        {
            name: "api gui",
            url: "",
        },
        {
            name: "Celo Node",
            url: "",
        }
    ]
};
